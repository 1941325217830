.customSelectError {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    gap: 0.15rem;
    font-weight: 600;
    color: rgb(239 68 68);
    background-color: rgb(254 226 226);
    border-radius: 0.375rem;

    svg {
        margin-bottom: 5px;
    }
}