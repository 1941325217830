.card {
    padding: 2rem;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);

    label {
        margin-bottom: .25rem;
        font-weight: bold;
    }
}

.icon_label {
    display: flex;

    :first-child {
        margin-right: 5px;
    }
}

.info_panel {
    padding: 2rem;
    border-radius: 5px;
    background-color: #009688;
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.save_icon {
    position: fixed;
    right: 15px;
    bottom: 25px;
    color: white !important;
    border-radius: 100% !important;
}


.back_icon {
    position: fixed;
    right: 80px;
    bottom: 25px;
    color: white !important;
    border-radius: 100% !important;
}

.switchWrapper {
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        text-align: center;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 46px;
        height: 28px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: #2196F3;
    }

    input:focus+.slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(19px);
        -ms-transform: translateX(19px);
        transform: translateX(19px);
    }
}