.vehicle_state_form {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    h4 {
        font-weight: bolder;
        width: 100%;
        margin-top: 0;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }

    .vehicle_state_form__fields {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;

        >div {
            width: calc(33.33% - 10px);
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;

            >div {
                width: 100%;
            }
        }

    }

    .vehicle_state_form__checks {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;

        >*{
            align-self: flex-start;
        }

        >label {
            width: fit-content;
        }
    }
}