.infoBoxWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: auto;

    border-radius: 4px;
    padding: .25rem .5rem;

    >span {
        margin-top: 2px;
    }
}

.info {
    border: 1px solid #17a2b8;
}

.warning {
    border: 1px solid #EDDF5D;
}

.error {
    border: 1px solid #ED0530;
}