.customInputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        margin-bottom: 0;
    }

    input {
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-weight: 400;
        line-height: 1.5;
        border-radius: 0.375rem;
        border: 1px solid #ced4da;
        background-color: #fff;

        &:focus {
            color: #495057;
            background-color: #fff;
            border-color: #ff5d0e;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(253, 172, 79, 0.473);
        }

        &:disabled {
            background-color: #e9ecef;
        }

        &::placeholder {
            backdrop-filter: opacity(0.6);
            font-size: 16px;
        }

    }
}