.basicBox {
  padding: 0.9rem;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
}

.marginBox {
  @extend .basicBox;
  margin: 15px 0px 8px 0px;
}


.alignFullWidth {
  width: 100%;
  text-align: center;

  table thead th {
    border: none;
    padding: 0;
  }

  table td {
    padding: 3px;
    border: none;
    vertical-align: middle;
  }

  table {
    border: none;
  }

}