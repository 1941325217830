.container{
    width: 50%;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 0.1rem; 
}

.collapse {
    padding: 1rem;
    border-radius: 8px;
    background-color: #EFEFEF;
    margin-bottom: 10px;
    margin-top: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    margin: 1rem;
}

.collapseContent{
    padding-left: 2rem;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    >tbody >tr >td{ 
        vertical-align: top;
    }
}


@media screen and (max-width: 768px) {
    .container{
        width: 100%;
    }
    
}