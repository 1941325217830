.vehicle_validation_sales_form {
    display: flex;
    flex-direction: column;

    @mixin field-groups {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;

        >* {
            width: calc(33.33% - 10px);
        }

        >h4 {
            width: 100%;
            font-weight: bolder;
        }

        @media screen and (max-width: 768px) {
            >* {
                width: 100%;
            }
        }
    }

    .vehicle_validation_sales_form__wrapper {
        display: flex;
        flex-direction: column;
        gap: 3rem;

        .vehicle_validation_sales_form__particular {
            @include field-groups();

            .vehicle_validation_sales_form__particular__offer__text {
                font-style: italic;
                color: #ff5d03;
            }
        }

        .vehicle_validation_sales_form__financing {
            @include field-groups();
        }

        .vehicle_validation_sales_form__supplied {
            @include field-groups();
        }

        .vehicle_validation_sales_form__operationalPrices {
            @include field-groups();
        }

        .vehicle_validation_sales_form__operationType {
            @include field-groups();

            .vehicle_validation_sales_form__operationType__buyAndSell {
                display: flex;
                flex-direction: row;
                align-items: center;

                >svg {
                    margin-bottom: 2px;
                    margin-left: 5px;
                }
            }
        }

        .vehicle_validation_sales_form__buyAndSell {
            @include field-groups();
        }

        .vehicle_validation_sales_form__salesManagement {
            @include field-groups();
        }
    }
}