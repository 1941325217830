.inputFileContainer {
    width: 100%;

    .inputFileLabelContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: .5rem;
        width: 100%;
        cursor: pointer;
    }

    input[type="file"] {
        display: none;
    }

    .inputFileCardContent {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .inputFileCardContentTrash {
            cursor: pointer;

            >path {
                stroke: #ff0000;
            }

        }
    }

    .inputFileError {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        font-weight: 600;
        color: rgb(239, 68, 68);
    }
}