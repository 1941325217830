.eventsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;

    @media screen and (max-width: 1200px) {
        margin: 0;
        margin-bottom: 1rem;;
    }

    .eventsHeaderDate {
        font-size: 22px;

        @media screen and (max-width: 1200px) {
            font-size: 18px;
        }

    }

    .arrows {
        display: inline-flex;
        position: relative;
        vertical-align: middle;

        .arrowButton {
            color: #fff;
            border: 1px solid transparent;
            border-color: #2c3e50;
            background-color: #2c3e50;
            border-radius: 0.3em;
            line-height: 1.5;
            padding: 0.4em 0.65em;

            &:first-child {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }

            &:last-child {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                margin-left: -1px;
            }

            @media screen and (max-width: 1200px) {
                padding: .2rem .35rem;
            }
        }

        @media screen and (max-width: 1200px) {
            gap: 1rem;
        }
    }
}