.container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    padding-top: 0;

    >* {
        flex-basis: 0;
        min-width: 100%
    }

    @media screen and (min-width: 1024px) {
        >* {
            min-width: calc(33% - 20px);
        }
    }
}