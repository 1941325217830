.vehicle_validation_data_form {
    display: flex;
    flex-direction: column;

    .vehicle_validation_data_form__buttons {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
        float: right;
        gap: 10px;

        @media screen and (max-width: 768px) {
            justify-content: center;
        }
    }

    .vehicle_validation_data_form__wrapper {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }
}