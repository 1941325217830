$--var-color-active: #D34600;
$--var-color-disabled: #959495;

.customRadioButton {
    width: 100%;
    display: flex;
    flex-direction: column;

    .customRadioButtonWrapper {
        display: flex;
        gap: 1.25rem;

        .customRadioButtonInput {
            display: flex;
            align-items: center;
            gap: .35rem;

            span {
                cursor: pointer;
            }

            .disabled {
                color: $--var-color-disabled;
                cursor: not-allowed;
            }

            input[type="radio"] {
                -webkit-appearance: none;
                appearance: none;
                margin: 0;
                font: inherit;
                width: 1.15em;
                height: 1.15em;
                border: 0.15em solid #6B6B6B;
                border-radius: 50%;
                transform: translateY(-0.075em);

                display: grid;
                place-content: center;
                cursor: pointer;

                &::before {
                    content: "";
                    width: 0.65em;
                    height: 0.65em;
                    border-radius: 50%;
                    transform: scale(0);
                    transition: 120ms transform ease-in-out;
                    box-shadow: inset 1em 1em $--var-color-active
                }

                &:checked {
                    border: 1px solid $--var-color-active;

                    &::before {
                        transform: scale(1);
                    }
                }

                &:disabled {
                    color: $--var-color-disabled;
                    cursor: not-allowed;
                    background-color: #fff;
                    border: 1px solid $--var-color-disabled;

                    &::before {
                        box-shadow: inset 1em 1em $--var-color-disabled;
                    }
                }
            }
        }
    }
}