.divider {
    width: 100%;
}

.inputWrapper {
    display: flex;
    flex-direction: column;

    label {
        margin-bottom: 0;
    }
}

.caseInfo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1rem;

    @media screen and (max-width: 768px) {
        display: flex;
        flex-flow: column;
    }

    span {
        span {
            font-weight: bold;
        }
    }
}

.caseState {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 2rem;

    >div {
        width: 30%;
    }

    >span {
        font-weight: bold;
    }

    @media screen and (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
    }
}