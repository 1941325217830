.basicBox {
  padding: 0.9rem;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
}

.marginBox {
  @extend .basicBox;
  margin: 15px 0px 8px 0px;
}

.noDecoration {
  color: inherit;
  text-decoration: none;
}

.center {
  display: flex;
  justify-content: center;
}

.rowSpace {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.centerMargin {
  @extend .rowSpace;
  @extend .center;
  margin-top: 15px;
  flex-wrap: wrap;
}

.centerPointer {
  @extend .center;
  cursor: pointer;
}

.wrapRowSpace {
  @extend .rowSpace;
  padding: 10px;
  flex-wrap: wrap;
}

.width20rowSpace {
  @extend .rowSpace;
  width: 20%;
}

.dateSize {
  width: 140px;
}

.semaforo {
  width: 20px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.marginPointer {
  @extend .center;
  cursor: pointer;
  margin: 0 15px;
}

.iconBox {
  @extend .center;
  width: 40px;
}

.warrantyBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(216, 208, 216, 0.69);
  background-color: #fbfcff;
  border-radius: 7px;
  box-shadow: 13px 11px 17px -8px rgba(0, 0, 0, 0.46);
  padding: 10px;
  margin: 15px 10%;
  text-align: center;
}

.h2Size {
  font-size: x-large;
  margin-left: 20px;
}

.vehicleTotalAmount {
  float: right;
  font-weight: bold;
  margin: 20px 0;
}

@media (max-width: 600px) {
  .semaforo {
    width: 60px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-content: space-around;
  }
}