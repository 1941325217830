.box {
  display: flex;
  align-items: center;
  width: 80px;
}

.container {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  display: flex;
}
