.vehicle_doc_upload_form {
    margin-bottom: 2rem;

    .vehicle_doc_upload_form__document__type {
        display: flex;
        gap: 2rem;
        align-items: center;
        margin-bottom: 1rem;

        >:first-child {
            width: 50%;
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;

            >:nth-child(n) {
                width: 100%;
            }
        }
    }
}