.checkboxL {
  transform: scale(2);
  cursor: pointer;
}

//OLD
.container2 {
  padding: 0.9rem;
  background-color: #2c2c2c;
  display: flex;
  align-items: center;
}

.filtersContent{
  padding: 10px;
  box-shadow: 0px 0px 20px -7px rgba(0,0,0,0.75);
}

.fleft {
  float: left;
}

.fright {
  float: right;
  text-align: center;
}

.startRow{
  padding-top: 15px !important;
  //place-items: baseline !important;
}

.startCol{
  padding-top: 10px !important;
}

.calendarPreHeader{
  background: #0a3444;
  color: white;
  width: 160px;
  padding-left: 15px;
  border-radius: 13px 13px 0 0;
  align-items: center;
  margin: auto 10px;
}

Col {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

@media (min-width: 700px) {
  .calendarHeaderTitle{
    font-size: 14px;
  }

.calendarContent{
  min-height: 500px;
  border-right: 1px solid #0a344460;
  border-left: 1px solid #0a344460;
}
.calendarHeader{
  background: #0a3444;
  color: white;
  height: 50px;
  border-radius: 13px 13px 0 0;
  //align-items: center;
}

.mobile {
  display: none;
}
}

@media (max-width: 700px) {
  .calendarHeaderTitle{
    background: #0a3444;
    color: white;
    margin-top:15px;
  }

  .desktop {
    display: none;
  }
  }

.calendarBlock{
  margin: 3px 1px;
  padding: 3px;
  color: white;
  border-radius: 15px;
  align-items: center;
  background-color: #f0a23c;
}

.calendarArrows{
  margin: 0 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}

.calendarCol{
  border-right: 1px solid rgba(128, 128, 128, 0.479);
}

.numberCircle {
  border-radius: 50%;
  height: 15px !important;
  width: 35px !important;
  padding: 8px !important;
  margin-left: 5px;
  background: rgb(255, 113, 47) ;
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 12px;
}

.numberTotalCircle {
  border-radius: 50%;
  padding: 4px 10px;
  margin-left: 5px;
  background: rgb(255, 113, 47) ;
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 12px;
}

.white{
  color:white;
}

.legend {
  display: grid;
  list-style: none;
  padding: 10px 0px;
}

.legend span {
  border: 1px solid #ccc;
  border-radius: 9px;
  float: left;
  width: 40px;
  height: 25px;
  margin: 2px 5px;
}

.legend p {
  margin: 2px auto;
}

.cursor_pointer {
  cursor: pointer;
}