.cursor_pointer {
  cursor: pointer;
}

.table_head {
  background-color: #ff9800
}

.custom_button{
  color: black;
  background-color: #b3b3b3!important;
  border-color: #ff9800!important;
}

.custom_button:hover {
  background-color:#999999!important;
}

.custom_icon {
  font-size: 24px!important;
}