.appointmentTotal {
    font-size: 17px;
    font-weight: bold;

    .appointmentTotalText {
        color: #28a745;
    }

    .appointmentTotalCount {
        color: #007bff;
    }
}

.appointmentCalendar {
    table-layout: fixed;

    tr {
        text-align: center;

        th,
        td {
            min-width: 13%;
            max-width: 13%;
            width: 13%;

            &:first-child {
                min-width: 9%;
                max-width: 9%;
                width: 9%;
            }
        }

        td {
            &:first-child {
                font-weight: bold;
                vertical-align: middle;
            }
        }
    }
}

.appointmentCalendarChip {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.appointmentCalendarHeaderDay {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 35px;

    .appointmentCalendarHeaderDayText {
        margin-right: 5px;
        white-space: nowrap;
    }

    .appointmentCalendarHeaderDayCount {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: rgb(255, 113, 47);
        color: white;
        font-weight: bold;
    }
}