.commentsTable {
  width: 100%;
  overflow: auto;
  text-align: left;
  overflow-x: auto;

  table {
    width: 100%;
    table-layout: fixed;

    thead {
      th {
        padding: 10px;
      }
    }

    tbody {
      td {
        vertical-align: middle;
        font-size: 0.9rem;
        padding: 0.7rem;
        overflow: hidden;
        text-overflow: ellipsis;

        .groupIcon {
          display: flex;
          justify-content: center;
        }

        div {
          display: flex;

          button,
          a {
            background-color: transparent;
            border: 0;

            // Action buttons mobile label
            span {
              display: none;
            }
          }
        }
      }
    }
    .amount {
      text-align: right;
    }
    .gridContainer {
      display: flex;
      width: 70%;
      justify-content: space-between;
    }
    .gridItem {
      margin: 0 3rem;
    }
    .odd {
      background-color: #ffffff;
    }

    .even {
      background-color: #f2f2f2;
    }
    .alignLeft {
      text-align: left;
    }
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    margin: 0;
    text-align: inherit;

    table {
      border: 0;

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tbody {
        tr {
          display: flex;
          flex-direction: column;
          margin-bottom: 1rem;
          padding-top: 0.5rem;
          background-color: #efefef !important;
          margin-bottom: 10px;
          border-radius: 8px;
          box-shadow: 1px 3px 4px 0px #00000040;

          td {
            display: block;
            padding: 0.4rem 0.7rem;
            grid-gap: 0;
            flex-flow: column;
            width: 100%;
            border: none;
            font-size: 0.8rem;

            span {
              font-weight: bold;
            }

            &::before {
              content: attr(data-label);
              display: block;
            }

            &:last-child {
              padding-bottom: 1rem;

              .groupIcon {
                justify-content: flex-end;
                gap: 1rem;
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
      .amount {
        text-align: left;
      }
      .document {
        margin-bottom: 0;
        box-shadow: none;
        border-radius: 10px 10px 0 0;
        border: none;
      }
      .documentRow {
        border-radius: 0px 0px 10px 10px;
        td {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
        }
      }
      .gridContainer {
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-gap: 8px;
        margin: 0;
        width: 100%;
      }

      .fullWidth {
        grid-column: span 4;
      }
      .gridItem {
        margin: 0;
      }
    }
  }

  svg {
    cursor: pointer;
    color: #d34600;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.vehicleTotalAmount {
  float: right;
  font-weight: bold;
  margin: 20px 0;
}
.spinner {
  animation: spin 1s linear infinite;
}
