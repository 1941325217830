.form {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 1.5rem 2rem 1.5rem;
    justify-content: space-between;

    >* {
        width: calc(50% - .5rem);
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;

        >* {
            width: 100%
        }
    }
}

.actions {
    display: flex;
    justify-content: center;
    gap: 1rem;

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        width: 100%;
        margin: .5rem 2rem .5rem 2rem;
    }
}