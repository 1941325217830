.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    padding-top: 0;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
    }

    >div {
        flex-basis: 0;
        min-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (min-width: 1024px) {
            min-width: 50%;
        }
    }
}

.sellResume {
    width: 100%;

    @media screen and (min-width: 1024px) {
        width: 75%;
    }

    th,
    td {
        padding: 1rem;
    }

    th {
        padding-bottom: 0;
    }

    tbody {
        tr {
            td {
                &:last-child {
                    text-align: right;
                }
            }
        }

        tr:nth-child(even) {
            background-color: #EFEFEF;
        }

        tr:last-child {
            font-weight: bold;
        }
    }
}

.payMethods {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media screen and (min-width: 1024px) {
        width: 50%;
    }


    .payAmounts {
        display: flex;
        flex-direction: column;
        gap: .75rem;
        padding-top: 1rem;
    }

    .payTotalContainer {
        font-weight: bold;
        padding-top: 1.75rem;
        padding-bottom: 1rem;

        label {
            margin-bottom: 0;
        }

        input {
            font-weight: bold;
        }
    }

    .validatedAmount {
        font-size: 12px;
        color: #0D8500;
        font-weight: normal;
    }
}
.courtesy {
    color: #0D8500;
}
.mutuaInfo {
    margin-top: 2rem;
}