.table {
    th {
        width: 40%;

        :last-child {
            width: 20%;
        }
    }

    td,
    th {
        text-align: center;
        vertical-align: middle !important;
    }
}