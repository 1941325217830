.removeMessage {
  color: #ffff;
  background-color: grey;
  padding: 10px;
}

.subtabContent {
  font-size: 14px;
  padding: 8px;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.link {
  color: #ff5d0e;
}