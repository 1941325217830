.centerRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.centerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.filterAdjust {
  padding: 2px 0;
}

.companyAdjust {
  width: 100%;
  margin-top: 6px;
}

.bold {
  font-weight: bolder;
  margin: 20px 0;
}

.company_box {
  border-radius: .25rem!important;
  border: 1px solid #ffe6e6;
  padding: 20px 5px;
  margin: 20px 0px;
  background-color: #fdf8f8;
}

.container {
  margin: 30px auto;
  padding: 0.9rem;
  width: 80%;
  background-color: #2c2c2c;
  display: flex;
  align-items: center;
}
