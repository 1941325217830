.active {
  background-color: #FF5D0E;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.active:hover {
  background-color: #FF5D0E;
  font-weight: bold;
  color: #fff
}

.inactive {
  background-color: grey;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}

.inactive:hover {
  background-color: #FF5D0E;
  font-weight: bold;
  color: #fff
}