.previewer {
    width: 100%;
    height: 100%;
    border: none;
}

.deleteIcon {
    text-align: center;
    margin-left: 230px;
    background: white;
    border-radius: 100%;
    width: 27px;
    height: 27px;
    color: red;
    cursor: pointer;
}

.linkTitle {
    position: absolute;
    background: #ff5722;
    color: white;
    padding: 0 5px;
    border-radius: 10px;
}