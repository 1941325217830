.dropzone {
    display: grid;
    align-content: center;
    width: 100%;
    min-height: 200px;
    font-size: 18px;
    justify-content: center;

    .preview {
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }

    >span {
        grid-area: 1 / 1 / 2 / 2;
        width: 100%;
        height: 100%;
    }


    .dropzoneTextButton {
        padding: 0;
        color: #D34600;
        font-size: 18px;
    }
}

.dragActive {
    border: 1px #D34600 solid;
    border-radius: 5px;
}
