.title_container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem;
    margin-top: 1rem;

    &.primary {
        background-color: #2c2c2c;
        margin-bottom: 1rem;
    }

    &.secondary {
        background-color: #D1D1D1;
        margin-bottom: 0;
    }

    .title {
        margin-left: 1.5rem;
        padding-left: 1rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-left: 4px solid #f34c36;
        font-weight: bold;

        &.primary {
            color: #FFFFFF;
        }

        &.secondary {
            color: #181818;
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }

    .menuButton {
        position: absolute;
        right: 1rem;
        cursor: pointer;

        .popoverMenuButtonWrapper {
            position: absolute;
            right: 0;
            background-color: #EFEFEF;
            padding: 1rem;
            border-radius: 4px;
            z-index: 9999;

            .popoverMenuButton {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }
        }
    }
}