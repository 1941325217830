.vehicle_validation__main {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-self: center;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  .vehicle_validation__identification {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
    }

    >div {
      flex: 0 0 50%;

      >label {
        margin-bottom: 0;
      }
    }
  }

  .vehicle_validation__tabs {
    margin-top: 2rem;

    .vehicle_validation__actions {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1rem;
      float: right;
      gap: 10px;

      @media screen and (max-width: 768px) {
        justify-content: center;
      }
    }
  }
}