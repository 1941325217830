.divider {
    width: 100%;
}

.inputWrapper {
    display: flex;
    flex-direction: column;
    min-width: 150px;
    label {
        margin-bottom: 0;
    }

    >textarea {
        width: 100%;
        height: 110px;
        padding: 14px 16px 14px 16px;
        border-radius: 4px;
        font-weight: 400;
        line-height: 1.5;
        border: 1px solid #ced4da;
        background-color: #fff;

        &:focus {
            color: #495057;
            background-color: #fff;
            border-color: #ff5d0e;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(253, 172, 79, 0.473);
        }

        &::placeholder {
            backdrop-filter: opacity(0.6);
            font-size: 16px;
        }
    }

}

.newCommentForm {
    >div:first-child {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        >div {
            width:100%;
        }

        >span {
            font-weight: bold;
        }
    }


    >div:nth-child(2) {
        display: flex;
        width: 100%;
        gap: 20px;
        margin-top: 2rem;

        .newCommentText {
            width: 80%;
        }

        .newCommentAmount {
            width: 15%;
        }
    }

    button[type=submit] {
        margin-top: 20px;
        float: right;

    }
}

.newCommentDoc {
    display: flex;
    margin-top: 20px;

    >span {
        display: flex;
        align-items: center;
        gap: 10px;

        >svg {
            cursor: pointer;
        }

        >span {
            margin-top: 5px;
            font-size: 17px;
            line-height: initial;
        }
    }
}
.sectionCommentAndAmmount{
    @media screen and (max-width: 980px){
        display: flex;
        flex-direction: column;
    }
}

.customSelectError {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    gap: 0.15rem;
    font-weight: 600;
    color: rgb(239 68 68);
    background-color: rgb(254 226 226);
    border-radius: 0.375rem;

    svg {
        margin-bottom: 5px;
    }
}