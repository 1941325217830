.table {
    td,
    th {
        width: 20%;
        text-align: center;
        vertical-align: middle !important;

        &:first-child,
        &:nth-child(4),
        &:nth-child(6) {
            width: 13%;
        }
    }
}

.card {
    padding: 2rem;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}