
.title {
  text-align: center;
}

.css-53l18y-ReactDropdownSelect {
    margin: 0.25em 0;

}
.flex {
    padding-bottom: 10px;
    display: flex;
}
.wrapper.text-center.sub-menu {
    margin-bottom: 20px;
}
.notAllowed {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: not-allowed;
}
.count{
    background-color: #ffc1a2;
    border-radius: 100%;
    color: black;
    padding: 2px 5px
}

.mercedes{
    display:none !important
}

@media only screen and (max-width: 600px) {
.bgroup{
    display:none !important;
}
.react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom {
    max-width: 30px !important;
}
}