.container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    padding-top: 0;

    >* {
        flex-basis: 0;
        min-width: 100%;

        &:last-child {
            flex: 0 0 100%;
        }
    }

    @media screen and (min-width: 1024px) {
        >* {
            flex-basis: 0;
            min-width: calc(33% - 20px);
        }
    }
}