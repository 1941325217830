.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: .5rem 1.5rem;


    .historial {
        width: 100%;
        margin-bottom: 1rem;

        >tbody tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, .05);
        }
    }

    .pagination {
        align-self: end;
    }
}