.casesTable {
  width: 100%;
  overflow: auto;
  text-align: center;
  overflow-x: auto;

  table {
    width: 100%;
    table-layout: fixed;

    thead {
      th {
        padding: 10px;
      }
    }

    tbody {
      td {
        vertical-align: middle;
        font-size: .9rem;
        padding: .7rem;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:last-child {
          >div {
            display: flex;
            justify-content: space-evenly;
          }
        }

        div {
          display: flex;

          button,
          a {
            background-color: transparent;
            border: 0;

            // Action buttons mobile label
            span {
              display: none;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    margin: 0;
    text-align: inherit;

    table {
      border: 0;

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tbody {

        tr {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(4, 1fr);
          align-items: start;
          border: 1px solid #000;
          margin-bottom: 1rem;

          td {
            display: block;
            flex-flow: column;
            width: 100%;
            border: none;
            font-size: 0.8rem;

            span {
              font-weight: bold;
            }

            &::before {
              content: attr(data-label);
              display: block;
            }

            &:last-child {
              grid-area: 5 / 1 / 5 / 3;
              padding-bottom: 1rem;

              div {
                justify-content: flex-end;
                gap: 1rem;
                margin-right: .5rem;
              }
            }
          }
        }
      }
    }
  }

  svg {
    cursor: pointer;
    color: #D34600
  }
  @media screen and (max-width: 980px) {
    tr {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
      grid-gap: 8px;
      background-color: #fff !important;
      margin-bottom: 10px;
      border-radius: 8px;
      box-shadow: 0px 2px 4px 0px #00000040;
    }
 
    thead {
      display: none;
    }
 
    td:nth-child(1) {
      font-weight: bold;
      grid-row: 1;
      grid-column: 1;
    }
 
    td:nth-child(3) {
      grid-row: 2;
      grid-column: 1;
    }
 
    td:nth-child(2) {
      grid-row: 3;
      grid-column: 1;
    }
 
    td:nth-child(4) {
      grid-row: 3;
      grid-column: 2 / span 2;
    }
  }
 
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 1s linear infinite;
}
.tableContainer {
  position: relative;
  height: 100%;
}

.spinnerOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
}

.disabledTable {
  pointer-events: none;
  opacity: 0.6;
}
