.row {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}

.margin10 {
    margin: 10px;
}

.noDecoration {
    color: inherit;
    text-decoration: none;
    align-items: center;
    display: flex;
}

.center {
    display: flex;
    justify-content: center;
}

.rowSpace {
    display: flex;
    flex-direction: row;
}

.centerMargin {
    @extend .rowSpace;
    display: flex;
    flex-direction: row;
    margin: 8px auto;
    flex-wrap: wrap;
}

.arrowContainer {
    width: 20px
}


.marginPointer {
    @extend .center;
    cursor: pointer;
    margin: 0 5px;
    background-color: transparent;
    border: none;
}

.marginPointerArrow {
    @extend .center;
    width: 30px;
    font-size: 24px;
    cursor: pointer;
    margin: 0 5px;
    background-color: transparent;
    border: none;
    padding-left: 50px;
}

.smallFont {
    font-size: 12px;
    font-weight: 600;
}

.bigIconBox {
    @extend .center;
    width: 60px;
}

.iconBox {
    @extend .center;
    width: 30px;
}

.iconCarBox {
    @extend .center;
    width: 10px;

    padding-left: 10px;
    margin: 0px;
}


.icon {
    @extend .center;
    margin-left: 2px;
    font-size: 35px;
}

.warningIcon {
    @extend .icon;
    color: red;
}

.iconsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.okIcon {
    @extend .icon;
    color: green;
}

.pendingIcon {
    @extend .icon;
    cursor: pointer;
    color: blue;
}

.refreshIcon {
    @extend .icon;
    cursor: pointer;
}



.marginPointerBox {
    @extend .marginPointer;
    border: 1px solid blue;
    border-radius: 5px;
    padding: 1px;
}


.btnFlexicarMini {
    margin: auto 2px;
    padding: 1px 8px;
    color: #fff !important;
    border-radius: 5px;
    font-weight: bold;
    font-size: 10px;
    width: 100% !important;
}

.green {
    background-color: green !important;
    border-color: green !important;
}

.red {
    background-color: red !important;
    border-color: red !important;
}

.grey {
    background-color: grey !important;
    border-color: grey !important;
}

.yellow {
    background-color: #ffc107 !important;
    border-color: #ffc107 !important;
}

.disabledBtn {
    cursor: not-allowed !important;
}

.buttonContainer {
    display: flex;
}


.tableContainer {
    width: 100%;

    table thead th {
        border: none;
        padding: 10px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
    }

    table td {
        padding: 3px;
        border: none;
        vertical-align: middle;
    }

    @media screen and (max-width: 980px) {
        width: 100%;
        margin: 0;

        .centerMargin {
            justify-content: flex-start;
        }

        table {
            border: 0;

            tbody {
                margin-top: 20px;
            }

            thead {
                border: none;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            tr.tr_files {
                display: block;
                margin-bottom: 1.5rem;
            }

            tr:not(.tr_files) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                /* 2 columnas */
                grid-template-rows: repeat(3, auto);
                /* 3 filas */
                gap: .5rem;
                justify-items: center;

                td {
                    display: flex;
                    flex-flow: column;
                    font-size: 0.8rem;
                    width: 100%;
                    height: 100%;

                    span {
                        font-family: Arial, Helvetica, sans-serif;
                        font-weight: bold;
                    }

                    &::before {
                        font-weight: bold;
                        content: attr(data-label);
                    }

                    &:nth-child(1) {
                        grid-area: 4 / 3 / 4 / 2;
                        padding-top: 1.6rem;
                        padding-right: 3rem;
                        z-index: 2;

                    }

                    &:nth-child(2) {
                        grid-area: 1 / 1 / 2 / 2;
                        padding-top: 1.6rem;
                        padding-left: 2rem;

                    }

                    &:nth-child(3) {
                        grid-area: 1 / 2 / 2 / 3;
                        padding-top: 1.5rem;
                        padding-left: 2rem;

                    }

                    &:nth-child(4) {
                        grid-area: 2 / 1 / 3 / 2;
                        padding-left: 2rem;
                    }

                    &:nth-child(5) {
                        grid-area: 2 / 2 / 3 / 3;
                        padding-left: 2rem;
                    }

                    &:nth-child(6) {
                        grid-area: 3 / 1 / 4 / 2;
                        padding-left: 2rem;

                    }

                    &:nth-child(7) {
                        padding-left: 2rem;
                        grid-area: 4 / 1 / 4 / 3;
                        display: flex;
                        z-index: 1;

                    }

                    &:nth-child(8) {
                        grid-area: 5 / 1 / 5 / 3;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        z-index: 1;
                    }

                    &:nth-child(9) {
                        padding-left: 3rem;
                    }

                    &:nth-child(10) {
                        grid-area: 4 / 1 / 4 / 3;
                        padding-left: 2rem;
                        display: flex;
                        height: fit-content;
                        margin-top: 1.9rem;
                        margin-left: 8rem;
                        flex-direction: row;
                        width: 10%;
                        z-index: 3;

                    }
                }
            }
        }
    }
}