.input {
  width: 100%;
  box-sizing: border-box;
}

.input_number {
  max-width: 100px !important;
}

.modal_header {
  background-color: #ff9800;
}

.btn_custom {
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}

.btn_custom:hover {
  background-color: #e88a00 !important;
  border-color: #e88a00 !important;
}

.filter {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 400px;
  margin-left: 7%;
}

.error {
  color: #ba3939;
  font-size: 0.8rem;
  margin-left: 5px;
}

