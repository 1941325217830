.checkboxContainer {
  margin-bottom: 24px;
  span {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
  }
  input[type="checkbox"]:checked {
    accent-color: #ff5d0e;
  }
}

.checkboxGrid {
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;

  div {
    display: flex;
    align-items: baseline;
  }

  label {
    padding-left: 12px;
  }
}

.infoContainer {
  text-align: right;
  font-weight: bold;
}

@media screen and (max-width: 980px) {
  .checkboxGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}