
.title {
    text-align: center;
  }
  
  .css-53l18y-ReactDropdownSelect {
      margin: 0.25em 0;
  
  }
  .flex {
      padding-bottom: 10px;
      display: flex;
  }
  .wrapper.text-center.sub-menu {
      margin-bottom: 20px;
  }
  .bgroup {
      margin: 20px 5px;
  }
  .outline{
      border:none !important;
      border-bottom: 1px solid #ccc !important;
          width: 100%;
  }

  .error {
    color: #ba3939;
    font-size: 0.8rem;
    margin-left: 5px;
  }

.modal_item {
    width: 25%;
}

@media screen and (max-width: 768px) {
    .modal_item {
        width: 100%;
    }
}
  