.container {
  padding: 0.9rem;
  background-color: #2c2c2c;
  display: flex;
  align-items: center;
}

.dropdown {
  display: flex;
  width: 250px;
  background-color: white;
  position: absolute;
  top: -12px;
  right: 0px;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 8px;
  > button {
    width: 80%;
    height: 20%;
    margin: 10px;
  }
}

.backDrop {
  z-index: 9998;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.containerMenu{
  position:relative;
}