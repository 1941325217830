.documentsWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0;
    justify-content: space-between;


    >div {
        flex: 0 0 calc(50% - 2rem);
        margin-bottom: 2rem;

        @media screen and (max-width: 768px) {
            flex: 0 0 100%;
        }
    }
}