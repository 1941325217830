.sidebar_container {
    padding: 10px;
    box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.75);
}

.main_content {
    flex: 1;
    display: flex;
    flex-flow: column;
    align-items: center;

    .content_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        z-index: 0;
    }
}