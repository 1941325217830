.appointments {
    margin-bottom: 2rem;

    .formAppointment {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        gap: 20px;
        width: 65%;
        margin-top: 1rem;

        >div {
            flex: 0 0 45%;
        }

        >button {
            flex: 0 0 10%;
        }

        @media screen and(max-width: 768px) {
            width: 100%;
            flex-direction: column;
            align-items: baseline;

            >div,
            >button {
                width: 100%;
            }
        }
    }


    .appointmentsActiveWrapper,
    .appointmentsInctiveWrapper {
        margin-top: 2rem;

        >div {
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            gap: 1.5rem;

            @media screen and(max-width: 768px) {
                width: 100%;
            }
        }
    }
}

.modalButtons {
    display: flex;
    gap: .5rem;
}