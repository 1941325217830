.container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    padding-top: 0;

    >* {
        flex-basis: 0;
        min-width: 100%;

        &:last-child {
            flex: 0 0 100%;
        }
    }

    @media screen and (min-width: 1024px) {
        >* {
            flex-basis: 0;
            min-width: calc(50% - 20px);
        }
    }
}

.infoContainer {
    display: flex;
    align-items: center;
    padding-top: 0;
    gap: 1rem;
    padding: 1rem;

    

    @media screen and (min-width: 1024px) {
        >* {
            flex-basis: 0;
            min-width: calc(33% - 20px);
        }
    }

    div span:first-child {
        font-weight: normal; /* Títulos */
    }

    div span:last-child {
        font-weight: bold; /* Datos en negrita */
    }
}   