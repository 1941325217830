.centerRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.filterAdjust {
  padding: 3px 0;
  margin-top: 2px;
}

.companyAdjust {
  width: 100%;
  margin-top: 15px;
}

