
.back{
  
    display: block;
    border-radius: 100%  !important;
    margin-right: 5px;
}
.print{
   
    display: block;
    color:white !important;
    border-radius: 100%  !important;
    margin-right: 5px;
}
.photo{
   
   
    color:white !important;
    border-radius: 100%  !important;
   
    margin-right: 5px;
}
.contrato{
   
    display: none !important;
    color:white !important;
    border-radius: 100%  !important;

    margin-right: 5px;
}
 