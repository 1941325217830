.vehicle_characteristics_form {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;

    h4 {
        font-weight: bolder;
        width: 100%;
        margin-top: 0;
    }

    >div {
        width: calc(33.33% - 10px);
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: .5rem;

        >div {
            width: 100%;
        }
    }

    .vehicle_characteristics_form__consumption {
        display: flex;
        flex-direction: column;

        label {
            margin-bottom: 0;

            >div {
                display: flex;

                >div {
                    width: 33.33%;
                }
            }
        }
    }
}