.overflow {
    overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.modal_header {
  background-color: #ff9800;
}

.btn_custom {
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}

.btn_custom:hover {
  background-color: #e88a00 !important;
  border-color: #e88a00 !important;
}