.titleContainer {
  padding: 0.9rem;
  background-color: #2c2c2c;
  display: flex;
  align-items: center;
}

.modalContainer {
  padding: 0 2rem;
}

.textTable {
  font-size: 16px;
}

.tableContainer {
  width: 100%;
  text-align: left;

  table thead th {
    border: none;
    padding: 10px 0;
  }

  table td {
    padding: 3px;
    border: none;
    vertical-align: middle;
    font-size: 90%;
  }


  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;

    table {
      border: 0;


      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1 0 0;
        border-radius: 4px;
        background: var(--Neutros-2, #EFEFEF);
        /* Shadow below */
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        margin-bottom: 1rem;
      }

      td {
        margin: 8px;
        display: flex;
        flex-flow: column;
        font-size: .8rem;
        width: 100%;
        height: 100%;


        span {
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 900;
        }

        &::before {
          font-weight: bold;
          content: attr(data-label);
        }

        button {
          width: 85%;
        }
      }
    }
  }
}

.grey {
  color: gray;
}