.wrapper {
    margin: 1.5rem auto;
}

.principal {
    position: absolute;
    background: #ff5722;
    color: white;
    padding: 0 5px;
    border-radius: 10px;
}

.delete {
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    border-radius: 100%;
    width: 27px;
    height: 27px;
    color: red;
}

.gallery {
    margin: 5px;
    border-radius: 10px;
    float: left;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
    transition: transform .4s;
    position: relative;

    :hover {
        transform: scale(1.1);
    }

    img {
        width: 100%;
        height: 140px;
        border-radius: 10px 10px 0px 0px;
    }
}