.flex {
  display: flex;
}

.container {
  height: 99vh;
}
.timeStamp {
  color: grey;
  font-size: 1.5vh;
  font-weight: lighter;
}
.title {
  font-weight: bold;
}

.close {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}

.textBox {
  width: 100%;
}
.commentsContainer {
  width: 100%;
  padding: 2px 0;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-height: 50vh;
  border: 1px solid black;
  border-radius: 4px;
  overflow-y: auto;
}

.comment {
  flex-direction: column;
  display: flex;
  background-color: rgb(255, 255, 250);
  width: 99%;
  padding: 2%;
  margin: 4px 0;
  border-radius: 4px;
  border: 1px solid black;
}

.disabled {
  opacity: 40%;
  pointer-events:none;
}

.advice {
  border-right: 2px solid white;
  align-self: center;
  margin-right: 10px;
  padding-right: 10px;
}

.modal_header {
  background-color: #ff9800;
}

.btn_custom {
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}

.btn_custom:hover {
  background-color: #e88a00 !important;
  border-color: #e88a00 !important;
}