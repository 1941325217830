.checkboxL {
  transform: scale(2);
  cursor: pointer;
}

.select_agent_state_lead {
  width: 35%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.paginationSells {
  display: flex;
  padding-right: 2rem;
  width: 100%;
  justify-content: right;

  @media screen and (max-width: 768px) {
    flex-flow: row-reverse;
    width: 100%;
    padding-right: 0;
    justify-content: space-between;
  }

  @media screen and (max-width: 992px) {
    margin-top: 1.5rem;
  }
}


.table_container {
  width: 100%;
  overflow: auto;
  text-align: center;

  table {
    thead {
      th {
        padding: 10px;
      }
    }

    tbody {
      td {
        vertical-align: middle;
        font-size: .9rem;
        padding: .7rem;

        div {
          display: flex;

          button, a {
            background-color: transparent;
            border: 0;

            // Action buttons mobile label
            span {
              display: none;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    margin: 0;
    text-align: inherit;

    table {
      border: 0;

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tbody {
        tr {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          justify-items: center;
          border: 1px solid #000;
          margin-bottom: 1rem;
          position: relative;

          td {
            display: flex;
            flex-flow: column;
            font-size: .8rem;
            width: 100%;
            height: 100%;
            border: none;
            padding-bottom: 0;

            span {
              font-weight: 900;
              font-family: Arial, Helvetica, sans-serif;
            }

            &::before {
              font-weight: bold;
              content: attr(data-label);
            }

            &:first-child {
              position: absolute;
              right: 16px;
              top: 50px;
              width: max-content;
              height: max-content;
            }

            &:nth-child(2) {
              position: absolute;
              right: 10px;
              width: max-content;
              height: max-content;
            }

            &:nth-child(3) {
              grid-area: 1 / 1 / 2 / 2;
            }

            &:nth-child(4) {
              grid-area: 1 / 2 / 2 / 3;
            }

            &:nth-child(5) {
              grid-area: 2 / 1 / 3 / 2;
            }

            &:nth-child(6) {
              grid-area: 2 / 2 / 3 / 3;
            }

            &:nth-child(7) {
              grid-area: 3 / 1 / 4 / 2;
            }

            &:nth-child(8) {
              grid-area: 3 / 2 / 4 / 3;
            }

            &:nth-child(9) {
              grid-area: 4 / 1 / 5 / 2;
            }

            &:nth-child(10) {
              grid-area: 4 / 2 / 5 / 3;
            }

            &:nth-child(11) {
              grid-area: 5 / 1 / 6 / 3;
              padding-bottom: 1rem;

              div {
                justify-content: space-between;

                button, a {
                  width: 30%;
                  position: relative;
                  border: 1px solid #DADADA;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  outline: none;

                  // Action buttons mobile label
                  span {
                    display: initial;
                    font-weight: initial;
                    color: #498CF6;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}