.filter {
    display: flex;
    flex-flow: column;
    margin-right: 1rem;

    label {
        margin-bottom: 0;
        margin-top: 1rem;
    }

    @media screen and (max-width: 992px) {
        width: 100%;
    }
}

.table {
    td,
    th {
        width: 35%;
        text-align: center;
        vertical-align: middle !important;
    
        &:nth-child(3),
        &:nth-child(4) {
            width: 15%;

            >div {
                display: flex;
                justify-content: center;
                gap: 1.25rem
            }
        }
    }
}
