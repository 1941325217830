.container2 {
  padding: 0.9rem;
  background-color: #2c2c2c;
  display: flex;
  align-items: center;
}

.filtersContent {
  padding: 10px;
  box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.75);
}

.videoCount {
  display: flex;
  justify-content: right;
}
