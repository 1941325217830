.vehicle_basic_data_form {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    width: 100%;

    > :nth-child(2),
    > :nth-child(3),
    > :nth-child(4) {
        width: calc(33.33% - 10px);
    }

    > :nth-child(n+5) {
        width: calc(50% - 10px);
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: .5rem;

        > :nth-child(n) {
            width: 100%
        }
    }

    .vehicle_basic_data_form__title {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 100%;

        h4 {
            width: auto;
            font-weight: bolder;
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 0;
        }
    }
}