
.back{
  
    display: block;
    border-radius: 100%  !important;
    margin-right: 5px;
}
.print{
   
    display: block;
    color:white !important;
    border-radius: 100%  !important;
    margin-right: 5px;
}
.photo{
   
   
    color:white !important;
    border-radius: 100%  !important;
   
    margin-right: 5px;
}
.contrato{
   
    display: none !important;
    color:white !important;
    border-radius: 100%  !important;

    margin-right: 5px;
}
 .column{
      display:inline-block;
      position: relative; 
      margin-bottom: 20px
 }
 .figure{
     max-height: 150px;
     overflow: hidden;
 }
 .image{
      width: 100%
 }
 .delete{
     color: red;
     cursor:  pointer; 
     position: absolute;
     top: 0em;
     right: 1em;
 }