.local {
    color: #6b6b6b;
    margin: 1rem  0.5rem;
    display: flex;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    background-color: #efefef;
}
.tachado {
    color: #020202;
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    justify-content: center;
    width: -moz-fit-content;
    width: fit-content;
    text-decoration: line-through;
    background-color: #d7d5d5;
    position: absolute;
    top: -20px;
    font-weight: 800;
    font-size:1.3rem;
}
.oferta {
color: #020202;
display: flex;
padding: 0.5rem 1rem;
align-items: flex-end;
justify-content: flex-end;
font-weight: 800;
font-size: 1.3rem;
border: solid;
border-color: #ff5d0e;
}
.modalheader {
    border: none;
}
.modalheader h5 {
   font-size:16px 
} 

.text{
    margin-top: 10px;
}