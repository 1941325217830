.table {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: white;

    .container {
        display: flex;
        flex-direction: column;
        width: 70%;

        .row {
            display: flex;
            flex-direction: row;

            >div {
                flex: 1;
                padding: .5rem;
                text-align: left;

                &:last-child {
                    flex: .5;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                }
            }
        }

        .header {
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
        }
    }
}

@media screen and(max-width: 768px) {
    .collapse {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: center;

        >div {
            display: flex;
            flex-direction: column;
            width: calc(50% - 1rem);

            >span {
                &:last-child {
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                }
            }

            &:last-child {
                flex-direction: row;
                width: 100%;
                gap: .5rem;
                justify-content: flex-end;
                margin-right: 1rem;
            }
        }
    }

}