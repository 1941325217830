$--var-color-active: #D34600;
$--var-color-disabled: #959495;

.customCheckboxWrapper {
    display: flex;
    align-self: flex-end;

    label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0;

        span {
            cursor: pointer;
        }

        .disabled {
            color: $--var-color-disabled;
            cursor: not-allowed;
        }

        input[type="checkbox"] {
            /* Add if not using autoprefixer */
            -webkit-appearance: none;
            /* Remove most all native input styles */
            appearance: none;
            margin: 0;
            border: 1px solid #6B6B6B;
            border-radius: 0.15em;
            transform: translateY(-0.075em);

            display: grid;
            place-content: center;
            cursor: pointer;

            &::before {
                content: "";
                width: 1em;
                height: 1em;
                clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
                transform: scale(0);
                transform-origin: bottom left;
                box-shadow: inset 1em 1em #fff;
            }

            &:checked {
                background-color: $--var-color-active;
                border: 1px solid $--var-color-active;

                &::before {
                    transform: scale(1);
                }
            }

            &:disabled {
                color: $--var-color-disabled;
                cursor: not-allowed;
                background-color: #fff;
                border: 1px solid $--var-color-disabled;

                &::before {
                    box-shadow: inset 1em 1em $--var-color-disabled;
                }
            }
        }
    }
}