.commentWrapper {
    .comment {
        display: flex;
        flex-flow: row;
        gap: 20px;

        @media screen and (max-width: 980px) {
            flex-flow: column;
        }

        .commentInfo {
            width: 85%;
            display: flex;
            flex-flow: column;

            >span {
                color: #939393;
                font-weight: 400;

                &:first-child {
                    font-weight: bold;
                    color: inherit;
                }
            }
        }

        .commentAmmount {
            width: 15%;
            @media screen and (max-width: 980px) {
                width: 50%;
            }

            >label {
                margin-bottom: 0;
            }
        }
    }

    .commentFile {
        min-height: 60px;
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .commentFileName {
            display: flex;
            align-items: center;

            >span {
                margin-left: 5px;
                margin-top: 7px;
                font-weight: bolder;
                line-height: initial;
            }
        }


        >button {
            padding: 0;

            >svg {
                color: #307DF6;
            }
        }
    }

    .commentUpdateButton {
        float: right;
    }
}