.validatedBy {
    text-align: right;
    font-weight: bold;
    margin-bottom: 1.5rem;
}

.infoPrep {
    margin: 1.5rem 0;
}

.componentsHeader {
    display: grid;
    grid-template-columns: 1.25fr .75fr 4fr .75fr;
    grid-column-gap: 1rem;
    font-weight: bold;
}

.componentsForm {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    margin-bottom: 1.5rem;

    .bubblesTitle {
        display: flex;
        gap: 1rem;
        align-items: flex-end;
        width: 90%;

        .bubblesTitleText {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .bubbles {
            display: flex;
            gap: .5rem;
            align-items: flex-end;

            .stateBubble {
                display: flex;
                justify-content: center;
                border: 1px solid;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                line-height: 32px;
            }
        }

    }

    .component {
        display: grid;
        grid-template-columns: 1.25fr .75fr 4fr .75fr;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        align-items: center;

        @media screen and (max-width: 980px) {
            display: flex;
            flex-flow: column;

            >div {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                width: 100%;
                justify-content: space-between;
                align-items: center;


                &:nth-child(2) {
                    display: flex;
                    flex-flow: column;
                    align-items: normal;
                    margin-bottom: 1rem;
                }
            }
        }

        .componentName {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}