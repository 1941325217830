$base-duration: 2s;
$color-1: #F45000;

.loader {
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 4px;
    width: 48px;
    height: 48px;

    >div {
        position: relative;
        width: 100%;
        height: 100%;
        background: $color-1;
        transform: scale(0.0);
        transform-origin: center center;
        animation: loader $base-duration infinite linear;

        &:nth-of-type(1),
        &:nth-of-type(3) {
            animation-delay: 0.4s;
        }

        &:nth-of-type(2),
        &:nth-of-type(4) {
            animation-delay: 0.2s;
        }
    }
}

@keyframes loader {
    0% {
        transform: scale(0.0);
    }

    40% {
        transform: scale(1.0);
    }

    80% {
        transform: scale(1.0);
    }

    100% {
        transform: scale(0.0);
    }
}