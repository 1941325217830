.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal_header {
  background-color: #ff9800
}

.btn_custom {
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}

.btn_custom:hover {
  background-color: #E88A00 !important;
  border-color: #E88A00 !important;
}