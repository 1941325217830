.appointmentTotal {
    font-size: 17px;
    font-weight: bold;

    .appointmentTotalText {
        color: #28a745;
    }

    .appointmentTotalCount {
        color: #007bff;
    }
}


.appointmentAgenda {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 3rem;

    .appointmentAgendaDay {
        &:nth-child(odd) {
            background-color: #EFEFEF;
        }

        .appointmentAgendaDayEmpty {
            font-weight: bold;
        }

        .appointmentAgendDayText {
            margin-right: .5rem;
            font-weight: bold;
        }

        .appointmentAgendaDayCount {
            color: #D34600;
        }

        .appointmentAgendaHourChips {
            display: flex;
            flex-wrap: wrap;

            >div {
                text-align: center;
            }
        }
    }
}