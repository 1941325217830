.form {
    label {
        margin-bottom: 0;
        margin-top: 1rem;
    }

    >div {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;

        >span {
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
        }
    }

    .dataClientWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        >div {
            flex: 0 0 calc(33% - .5rem);
        }

        @media screen and (max-width: 768px) {
            >div {
                flex: 0 0 100% !important;
            }
        }
    }

    .dataVehicleWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        >div {
            flex: 0 0 calc(33% - .5rem);

            &:last-child,
            &:nth-last-child(2) {
                flex: 0 0 calc(50% - .5rem);
            }
        }

        @media screen and (max-width: 768px) {
            >div {
                flex: 0 0 100% !important;
            }
        }
    }

    .dataDealershipWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        >div {
            flex: 0 0 calc(33% - .5rem);

            &:nth-child(4),
            &:nth-child(5) {
                flex: 0 0 calc(50% - .5rem);

                >div {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    align-items: center;

                    >div {
                        display: flex;
                        gap: 20px;
                    }
                }
            }

            &:last-child {
                flex: 0 0 100%;
            }
        }

        @media screen and (max-width: 768px) {
            >div {
                flex: 0 0 100% !important;

                &:nth-child(4),
                &:nth-child(5) {
                    >div {
                        flex-direction: column;
                        gap: 0;

                        >div>label {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}

