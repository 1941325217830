.toolbarWrapper {
    display: flex;
    overflow: clip;
    width: 100%;
    align-items: center;
}

.overflowStyle {
    order: 20;
    position: sticky;
    right: 0;
    white-space: nowrap;
    margin: 0px 5px;
}