.green {
    background-color: green !important;
    border-color: green !important;
}

.red {
    background-color: red !important;
    border-color: red !important;
}

.grey {
    background-color: grey !important;
    border-color: grey !important;
}

.yellow {
    background-color: #ffc107 !important;
    border-color: #ffc107 !important;
}

.btnFlexicarMini {
    margin: auto 2px;
    padding: 1px 8px;
    color: #fff !important;
    border-radius: 5px;
    font-weight: bold;
    font-size: 10px;
    width: 100% !important;
}