.legend {
    list-style: none;

    li {
        position: relative;

        div.before {
            position: absolute;
            content: "";
            font-weight: bold;
            display: inline-block;
            border-radius: 1rem;
            width: 1em;
            margin-left: -2em;
            margin-right: .2em;
            margin-bottom: -.2em;
            padding: .5em .75em;
            top: 5px;
        }

        span {
            font-weight: bold;
        }
    }

}

.resetButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1.5rem;
    padding: 0.5rem;

    span {
        margin-right: 0.5rem;
        font-size: 1.1em;
        font-weight: bold;
    }
}