.container {
    position: relative;

    .comment {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .icon {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 3px;
        cursor: pointer;
    }
}