.chip {
    color: #fff;
    border-radius: 32px;
    font-weight: 500;
    font-size: 16px;
    padding: .25rem 1rem;

    font-weight: bold;
    cursor: pointer;


    @media screen and (max-width: 1200px) {
        margin-right: 0.5rem;
        margin-bottom: 1.25rem;
    }
}