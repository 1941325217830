.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    padding-top: 0;
    align-items: flex-start;

    >* {
        align-self: flex-start;
    }
}