.tableContainer {
    margin-left: 30px;

    table {
        border: none;
    }

    table thead th{
        border: none;
        padding: 0;
    }

    table td{
        padding: 0;
        border: none;
        vertical-align: middle;
    }


    @media screen and (max-width: 768px) {
      margin-left: 0px;
        table {
          border: 0;
        }
      
        table caption {
          font-size: 1.3em;
        }
        
        table thead {
          border: none;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
        
        table tr {
          border: 1px solid #000;
          display: block;
        }
        
        table td {
        //   border-bottom: 1px solid #ddd;
          display: block;
          font-size: .8em;
          text-align: right;
          border: none;
          padding: 0.2em 2em;
        }
        
        table td::before {
          /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
          content: attr(data-label);
          float: left;
          font-weight: bold;
        }
        
        table td:last-child {
          border-bottom: 0;
        }
      }
      

}