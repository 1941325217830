.createContractButton {
    display: flex;
    margin-left: auto;
    margin-top: 1rem;
    width: 100%;
    justify-content: center;

    @media screen and (min-width: 1024px) {
        width: auto;
        margin-left: auto;
    }
}

.stepHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.buttonContainer {
	display: flex;
    justify-content: flex-end;
	gap: 10px; 
}

