.align {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    align-content: flex-start;
}

.alignComment {
    max-width: 100%;
    overflow-wrap: break-word;
}

.centerRow {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
}

.bold {
    font-weight: bolder;
    margin: 20px 0;
}

















