.custom__box {
  padding: 10px;
  border-color: lightgray;
  cursor: pointer;
}

.custom__title__box {
  padding: 10px;
  border-color: lightgray;
}

.custom__box:hover {
  background-color: lightgray;
}

.custom__title__text {
  color: #ff9800;
  font-size: 30px;
  font-weight: bold;
}

.custom__text {
  font-size: 17px;
  font-weight: bold;
}

.icon__color {
  color: #ff9800;
}

@media (max-width: 1280px) {
  .custom__text {
    font-size: 15px;
  }
}

