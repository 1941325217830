.commentBlock {
  padding: 10px;
  background: #ffcd8a;
  border-radius: 15px;
}

.button{
  display: none;
  @media (max-width: 780px){
    display: inline;
  }

}

