.customTextAreaWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        margin-bottom: 0;
    }

    textarea {
        width: 100%;
        height: 110px;
        padding: 14px 16px 14px 16px;
        border-radius: 4px;
        font-weight: 400;
        line-height: 1.5;
        border: 1px solid #ced4da;
        background-color: #fff;

        &:focus {
            color: #495057;
            background-color: #fff;
            border-color: #ff5d0e;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(253, 172, 79, 0.473);
        }

        &::placeholder {
            backdrop-filter: opacity(0.6);
            font-size: 16px;
        }
        
        &:disabled {
            background-color: #e9ecef;
        }

    }
}

.customTextAreaMaxLengthInfo {
    display: flex;
    justify-content: flex-end;
}