
.title {
  text-align: center;
}

.css-53l18y-ReactDropdownSelect {
    margin: 0.25em 0;

}
.flex {
    padding-bottom: 10px;
    display: flex;
}
.wrapper.text-center.sub-menu {
    margin-bottom: 20px;
}
.bgroup {
    margin: 20px 5px;
}
.outline{
    border:none !important;
    border-bottom: 1px solid #ccc !important;
        width: 100%;
}
.floatingLabel {
  position:  relative; 
  margin-bottom:  10px; 
  .react-dropdown-select {
          border-bottom: 1px solid #ccc;
  }
  label {
      font-size:0.7rem;
    position:  absolute; 
    top: calc(50% - 10px);
    left:  12px; 
    opacity:  0; 
    transition:  all .3s ease;
    color:#9e9e9e
  }
  input:not(:placeholder-shown) {
    padding:  28px 10px 12px 10px; 
  }
  input:not(:placeholder-shown) + label {
    transform:  translateY(-10px); 
    opacity:  1; 
  }
  .filter input:not(:placeholder-shown) {
    padding:  5px 5px 0 0 ; 
  }
  .filter input:not(:placeholder-shown) + label {
    transform:  translateY(-20px); 
    opacity:  1; 
  }
  .filter span{
      padding-top: 12px;
  }
 .select{
    font-size: 0.7rem;
    position: absolute;
    top: calc(12% - 7px);
    left: 20px;
    opacity: 1;
    transition: all .3s ease;
    color: #9e9e9e;
}
.notSelect{
    opacity: 0;
}

}
.notAllowed {
  svg,input:disabled {
    cursor: not-allowed;
  }
} 