.paginationContainer {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .paginationItem {
        display: flex;
        align-items: center;
        text-align: center;

        padding: 0 10px;
        margin: auto 4px;
        height: 32px;
        border-radius: 4px;
        color: #6B6B6B;
        font-weight: bold;
        user-select: none;

        &.dots:hover {
            background-color: transparent;
            cursor: default;
        }

        &:hover {
            cursor: pointer;
        }

        &.selected {
            background-color: #D34600;
            color: #fff;
        }

        .arrow {
            color: #D34600;
        }

        &.disabled {
            pointer-events: none;

            .arrow {
                color: #f2c8b3;
            }

            &:hover {
                background-color: transparent;
                cursor: default;
            }
        }
    }
}