.createContractButton {
    display: flex;
    margin-left: auto;
    margin-top: 1rem;
    width: 100%;
    justify-content: center;

    @media screen and (min-width: 1024px) {
        width: auto;
        margin-left: auto;
    }
}

.changePriceModal {
    span {
        display: block;
    }
}