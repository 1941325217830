.commentWithBackground {
  padding: 10px;
  background: #ffcd8a;
  border-radius: 15px;
  overflow-wrap: break-word;
}

.comment {
  background: transparent;
  overflow-wrap: break-word;
  a {
    color: #D34600;
  }
}
