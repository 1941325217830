.form {
    >div {
        display: flex;
        width: 100%;
        gap: 25px;
        margin-bottom: 25px;
    }
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}


@media screen and (max-width: 768px) {
    .form>div {
        flex-wrap: wrap;
        gap: 20px;
    }
}