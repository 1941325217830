.form {
    padding: 2rem;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    
    label {
        margin-bottom: .25rem;
        font-weight: bold;
    }
}


.icon_label {
    display: flex;

    :first-child {
        margin-right: 5px;
    }
}

.info_panel {
    padding: 2rem;
    border-radius: 5px;
    background-color: #009688;
    color: white;
    font-size: 16px;
    font-weight: 600;
}