.classFilterList {
  background: #ff5d0e;
  color: #fff;
  border-bottom: 1px solid #fff;
  margin-left: 4px;
  padding: 4px;
  border-radius: 6px;
}

.avgBox {
  text-align: center;
  background-color: #f1f1f1;
  border-radius: 10px;
  align-self: flex-start;
  margin: 0 10px 20px 10px;
  padding: 10px 0;

  @media screen and (max-width: 980px) {
    flex-grow: 1;    
    flex-basis: 40%;
  }
}

.main {
  flex: 1;
  max-width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.filters_list {
  padding: 0;
  margin: 0;
  padding-left: 10px;
  padding-bottom: 10px;
}
