.custom__position {
  position: relative;
  right: 5px;
  top: 6px;
}

.custom__position__single {
  position: relative;
  right: 5px;
}

.custom__position__text__single {
  position: relative;
  top: 5px;
}

.custom__position__expand {
  color: #ff9800;
  position: relative;
  top: 9px;
}

.custom__position__text {
  color: #ff9800;
  position: relative;
  top: 12px;
}

.custom__position__text:hover {
  text-decoration: none;
}

.custom__position__navlink__text {
  color: #ff9800;
  position: relative;
  top: 12px;
}

.custom__position__navlink__text:hover {
  text-decoration: none;
}

.custom__position__navItem {
  color: #ff9800;
  position: relative;
  right: 5px;
  top: 6px;
}

.custom__link {
  color: rgba(0, 0, 0, 0.87) !important;
  text-decoration: none !important;
  padding: 0.5rem 1rem;
}

.custom__hover:hover {
  color: lightgray !important;
}

.navbar {
  background-color: #f8f9fa !important;
  display: flex;
  justify-content: space-between;
}

.navbar_menu {
  display: flex;
}

.menu {
  display: flex;
  gap: 1em;
  margin: 0;
  padding: 0;
  position: relative;
}

.menu_li_container {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

.dropdown__container {
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.06);
  display: none;
  left: 0;
  padding: 0.5rem 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 76px;
  width: fit-content;
  z-index: 1;
}

.dropdown__container__list {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  min-width: 0;
}

.dropdown__container__listItem {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown__container__listItem > div {
  color: black;
  cursor: pointer;
  overflow: hidden;
  padding: 0.25rem 1.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown__container__listItem div:hover {
  background-color: #e9ecef;
}

.menu li {
  cursor: pointer;
  display: flex;
  padding: 16px 0;
  position: relative;
  text-align: center;
  transition: background-color 0.5s ease;
  width: 100%;
}

.menu li:hover .dropdown__container {
  display: flex;
}

.hasDropdown {
  padding: 1rem 0rem;
}

.hasDropdown span {
  color: #ff9800;
  font-size: 18px;
}

.maxContent {
  width: max-content;
}

.menu_li_link {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

.menu_li_link h6 {
  font-size: 16px;
  margin: 0;
  top: 0;
}

.last_dropdown {
  left: unset;
}

.mobile-navbar {
  display: none;
}

@media only screen and (max-width: 1100px) {
  .navbar {
    display: none;
  }

  .mobile-navbar {
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1rem;
  }

  .mobile-navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-menu-container {
    margin: 1.2rem;
  }

  .show_navbar {
    animation: fade-in 0.35s forwards;
  }

  .mobile-menu-container:not(.show_navbar) {
    display: none;
    opacity: 0;
  }

  .mobile-menu {
    margin: 0;
    padding: 0;
  }
  .accordion-header button {
    background-color: transparent;
    border: none;
  }

  .accordion_body_container {
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin: 0 10px;
    padding: 10px;
  }

  .hasDropdown {
    padding: 0;
  }

  .hasDropdown span {
    font-size: 24px;
  }

  .single_item {
    margin-bottom: 8px;
    padding: 1px 6px;
  }
  .menu_li_container {
    justify-content: flex-start;
  }

  .hamburger_container {
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    height: 100%;
    padding: 4px 12px;
  }

  .custom__link {
    padding: 0.5rem;
    display:block;
  }
}


@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
