.collapsible {
    box-shadow: 0px 2px 4px 0px #00000040;
    transition: 0.3s;

    .collapsibleHeader {
        display: flex;
        justify-content: space-between;
        padding: .25rem 1rem;
        border-bottom: 1px solid #dee2e6 !important;

        .collapsibleHeaderTitle {
            display: block;
            width: 90%;
            font-size: 1em;
            margin-block-start: 0.83em;
            margin-block-end: 0.83em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: bold;
        }

        .collapsibleHeaderCaret {
            cursor: pointer;
            background-color: transparent;
            background-repeat: no-repeat;
            border: none;
            cursor: pointer;
            overflow: hidden;
            outline: none;

            -moz-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
            -webkit-transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
            transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        }
    }

    .collapsibleBody {
        overflow: hidden;
        transition: height 0.2s cubic-bezier(0.4, 0, 0.2, 1);

        .collapsibleBodyContent {
            padding: 1.5rem .5rem;
        }
    }
}