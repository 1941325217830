.cardWrapper {
    box-shadow: 0px 2px 4px 0px #00000040;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .cardHeader {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .cardContentTitle {
            display: flex;
            align-items: center;
            gap: 5px;

            >span {
                color: #6B6B6B;
                font-weight: bold;
                padding-top: 5px;
            }
        }

        .cardSubtitle {
            color: #6B6B6B;
            font-weight: bold;
        }

        .cardContent {
            color: #939393;
        }
    }

    .cardHeaderActions {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }
}