.newCommentForm {
    >div:first-child {
        display: flex;
        width: 100%;
        gap: 20px;
        margin-top: 1rem;

        @media screen and (max-width: 980px) {
            display: block;
        }

        .newCommentText {
            width: 80%;

            @media screen and (max-width: 980px) {
                width: 100%;
            }

        }

        .newCommentAmount {
            width: 15%;

            @media screen and (max-width: 980px) {
                width: 50%;
            }

        }
    }

    button[type=submit] {
        margin-top: 20px;
        float: right;

    }
}

.newCommentDoc {
    display: flex;
    margin-top: 20px;

    >span {
        display: flex;
        align-items: center;
        gap: 10px;

        >svg {
            cursor: pointer;
        }

        >span {
            margin-top: 5px;
            font-size: 17px;
            line-height: initial;
        }
    }
}