.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    padding-top: 0;

    .priceOptions {
        display: flex;
        width: 100%;
        justify-content: space-between;

        @media screen and (min-width: 1024px) {
            width: calc(33% - 20px);
        }
    }

    .module {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;

        >* {
            flex-basis: 0;
            min-width: 100%;

            &.alternativeAddress {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                min-width: 100%;

                >* {
                    min-width: 100%;
                }
            }
        }

        .extraWarrantyTooltip {
            display: flex;
            align-items: center;

            .extraWarrantyTooltipIcon {
                cursor: pointer;
                color: #007bff;
                margin-left: 0.2rem;
                margin-bottom: 0.2rem;
            }
        }


        @media screen and (min-width: 1024px) {
            >* {
                min-width: calc(33% - 20px);

                &.alternativeAddress {
                    flex-direction: row;

                    >* {
                        min-width: calc(25% - 20px);
                    }
                }
            }
        }
    }
}