@import "src/styles/colors";

.container {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  width: 70%;
  border-radius: 3px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 15px;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;
}

.button {
  background-color: $color-primary;
  border-radius: 6px;
  padding: 5px;
  width: 200px;
  height: 40px;
  border: none;
  color: white;
}
