.vehicle_validation_data__main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;

    .vehicle_validation_data__location {
        >div {
            display: flex;
            align-items: center;
            gap: 1rem;
            align-items: flex-end;

            >div {
                display: flex;
                flex-direction: column;
            }
        }

        @media screen and (max-width: 768px) {
            >div {
                flex-direction: column;
                flex: 1;
                align-items: flex-start;
            }
        }
    }
}