.casesTable {
  width: 100%;
  overflow: auto;
  text-align: center;
  overflow-x: auto;

  table {
    width: 100%;
    table-layout: fixed;

    thead {
      th {
        padding: 10px;
      }
    }

    tbody {
      td {
        vertical-align: middle;
        font-size: .9rem;
        padding: .7rem;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:last-child {
          >div {
            display: flex;
            justify-content: space-evenly;
          }
        }

        div {
          display: flex;

          button,
          a {
            background-color: transparent;
            border: 0;

            // Action buttons mobile label
            span {
              display: none;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    margin: 0;
    text-align: inherit;

    table {
      border: 0;

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tbody {

        tr {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(4, 1fr);
          align-items: start;
          border: 1px solid #000;
          margin-bottom: 1rem;

          td {
            display: block;
            flex-flow: column;
            width: 100%;
            border: none;
            font-size: 0.8rem;

            span {
              font-weight: bold;
            }

            &::before {
              content: attr(data-label);
              display: block;
            }

            &:last-child {
              grid-area: 5 / 2 / 5 / 3;
              padding-bottom: 1rem;

              div {
                justify-content: flex-end;
                gap: 1rem;
                margin-right: .5rem;
              }
            }
          }
        }
      }
    }
  }

  svg {
    cursor: pointer;
    color: #D34600
  }
}