
.title {
  text-align: center;
}

.css-53l18y-ReactDropdownSelect {
    margin: 0.25em 0;

}
.flex {
    padding-bottom: 10px;
    display: flex;
}
.wrapper.text-center.sub-menu {
    margin-bottom: 20px;
}
.bgroup {
    margin: 20px 5px;
}
.chat{
        display: inline;
    background-color: #cbcece;
    padding: 10px;
    border-radius: 10px;
}

.modal_header {
    background-color: #ff9800;
  }
  
  .btn_custom {
    background-color: #ff9800 !important;
    border-color: #ff9800 !important;
  }
  
  .btn_custom:hover {
    background-color: #e88a00 !important;
    border-color: #e88a00 !important;
  }