.modalContainer {
  padding: 0 2rem;
}

.table {
  tr {
    display: flex;
  }

  th,
  td {
    flex: 1;
  }
}

.pagination {
  justify-content: center;
}