.uploader {
    margin: 0 auto;
    background-color: aliceblue;
    padding: 10px;
    border: 1px solid #007bff;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);

    .uploader__input {
        position: absolute;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        outline: none;
        opacity: 0;
        top: 0;
        left: 0;
        cursor: pointer;
    }
}

.error {
    color: salmon;
}