.switch {
    position: relative;
    border-radius: 1rem;
    border: 1px solid #D34600;
    overflow: hidden;

    >input {
        appearance: none;
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;

        &:checked~span:first-of-type,
        &:not(:checked)~span:last-of-type {
            background: #D34600;
            color: #fff;
        }
    }

    >span {
        padding: .5rem .75rem;
        border-radius: inherit;
        color: #D34600;
        font-weight: bold;
        user-select: none;
        transition: all 100ms ease-out 0s;

        &:first-of-type {
            // Margin to match parent radius with child  corners
            margin-left: -1px;
        }

        &:last-of-type {
            // Margin to match parent radius with child  corners
            margin-right: -1px;

            // Margint to reduce gap between options
            margin-left: -5px;
        }


    }
}