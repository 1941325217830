.customInputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.suggestionsList {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 0;
    margin: 0;
    list-style: none;
}

.suggestionItem {
    padding: 8px;
    cursor: pointer;
}

.suggestionItem:hover,
.suggestionItem.selected {
    background-color: #f0f0f0;
}
